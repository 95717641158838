import React, { forwardRef } from "react"
import { Box, Flex } from "rebass"

export const Wrapper = props => (
  <Flex
    sx={{
      backgroundColor: "blueDark",
      borderRadius: "box",
      borderColor: "#0C62A4",
      paddingX: 3,
      paddingY: 2,
      borderWidth: "1px",
      borderStyle: "solid",
      width: ["100%", "100%", "363px"],
      justifyContent: "space-between",
      svg: {
        color: "white",
      },
    }}
    {...props}
  />
)

export const Input = forwardRef((props, ref) => (
  <Box
    as="input"
    ref={ref}
    sx={{
      backgroundColor: "transparent",
      color: "white",
      outline: "none",
      fontFamily: "heading",
      fontSize: 2,
      border: "none",
      "&::placeholder": {
        color: "white",
      },
    }}
    {...props}
  />
))
