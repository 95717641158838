import { debounce } from "lodash"
import { useCallback } from "react"
import routes from "@utils/routes"
import { navigate } from "gatsby-plugin-intl"
import { DEBOUNCE_TIME } from "./useSearch"

export function useNavigateToResultsList() {
  const navigateToResultsList = useCallback(
    debounce(
      term =>
        navigate(
          term
            ? `${routes.blog}?${new URLSearchParams([["q", term]])}`
            : routes.blog
        ),
      DEBOUNCE_TIME
    ),
    []
  )
  return navigateToResultsList
}
