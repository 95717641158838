import { Index } from "elasticlunr"
import { debounce } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { useNavigateToResultsList } from "./useNavigateToResultsList"
import ASCIIFolder from "fold-to-ascii"
export const DEBOUNCE_TIME = 500

export function useSearch(allData, indexObject, initialSearchTerm = "") {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)
  const navigateToResultsList = useNavigateToResultsList()
  const index = useMemo(() => Index.load(indexObject), [indexObject])
  const resultsIds = index
    .search(ASCIIFolder.foldReplacing(searchTerm), { expand: true })
    .map(({ ref }) => ref)
  const changeSearchFor = useCallback(
    debounce(setSearchTerm, DEBOUNCE_TIME),
    []
  )

  return {
    searchTerm,
    search: changeSearchFor,
    navigateToResultsList,
    results: resultsIds
      .map(id => allData.find(post => post.node.id === id))
      .filter(post => post),
  }
}
