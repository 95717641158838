import React, { useRef } from "react"
import { Search } from "react-feather"
import * as Styles from "./SearchInput.styles"

export default function SearchInput({ onSubmit, ...props }) {
  const inputRef = useRef()
  return (
    <Styles.Wrapper
      as="form"
      onSubmit={event => {
        event.preventDefault()
        onSubmit && onSubmit(inputRef.current?.value)
      }}
    >
      <Styles.Input ref={inputRef} {...props} />
      <Search onClick={() => onSubmit && onSubmit(inputRef.current?.value)} />
    </Styles.Wrapper>
  )
}
