import React from "react"
import Fade from "react-reveal/Fade"
import * as Style from "./PostItem.styles"

function PostItem({
  category,
  categorySlug,
  height,
  image,
  slug,
  timeToRead,
  title,
}) {
  return (
    <Fade cascade>
      <Style.Wrapper>
        {image && (
          <Style.PostImage
            category={category}
            categorySlug={categorySlug}
            slug={slug}
            height={height}
            image={image}
          />
        )}
        <Style.ContentLink to={slug}>
          <Style.InfoWrapper>
            <Style.TitleWrapper>
              <Style.TimeToRead timeToRead={timeToRead} />
              <Style.Title>{title}</Style.Title>
            </Style.TitleWrapper>
          </Style.InfoWrapper>
        </Style.ContentLink>
      </Style.Wrapper>
    </Fade>
  )
}

export default PostItem
