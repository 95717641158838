/** @jsx jsx */
import Translate from "@components/Translate"
import TimeIcon from "@images/svg/time.svg"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-intl"
import { Box, Flex, Text } from "rebass"
import { jsx } from "theme-ui"

export function Category({ children }) {
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "#006BA6",
        bottom: "0",
        left: "0",
        paddingY: 2,
        paddingX: 3,
      }}
    >
      <Text sx={{ a: { color: "white", textDecoration: "none" } }}>
        {children}
      </Text>
    </Box>
  )
}

export const Title = props => (
  <Text {...props} fontFamily="heading" fontSize={4} color="primary" />
)

export const Date = props => <Text {...props} fontSize={2} color="gray" />

export const Wrapper = props => (
  <Flex
    as="article"
    backgroundColor="white"
    flexDirection="column"
    height="100%"
    sx={{
      cursor: "pointer",
      boxShadow: "card",
      transition: "box-shadow .2s ease",
      borderRadius: 3,
      "&:hover": {
        boxShadow: "cardHovered",
      },
    }}
    {...props}
  />
)

export const ContentLink = props => (
  <Link
    sx={{
      height: "100%",
      textDecoration: "none",
    }}
    {...props}
  />
)

export const InfoWrapper = props => (
  <Flex
    flexDirection="column"
    justifyContent="space-between"
    flex={1}
    height="100%"
    p="3"
    {...props}
  />
)

export const PostImage = ({
  categorySlug,
  category,
  image,
  slug,
  height = [250, 300, 200],
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Link to={slug}>
        <Img
          sx={{
            height,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
          fluid={image.childImageSharp.fluid}
        />
      </Link>
      <Category mb={1}>
        <Link to={`/${categorySlug}`} onClick={e => e.stopPropagation()}>
          {category}
        </Link>
      </Category>
    </Box>
  )
}

export const CardLink = props => (
  <Link
    sx={{
      textDecoration: "none",
      alignSelf: "stretch",
      display: "flex",
    }}
    {...props}
  />
)

export const TimeToRead = ({ timeToRead }) => (
  <Flex
    sx={{
      alignItems: "center",
      color: "blue",
      margin: "10px auto",
      "> div": { ml: 1 },
    }}
  >
    <TimeIcon />
    <div>
      <Translate id="blog.readingTime" ml="2" />
    </div>
    <div>{timeToRead}</div>
    <div>min</div>
  </Flex>
)

export const TitleWrapper = props => <Box width={"100%"} {...props} />
